import React from "react"
import { graphql } from "gatsby"
import Reveal from "react-reveal/Reveal"
import ContentfulLink from "components/contentfulLink"
import WavyBackground from "components/wavyBackground"
import TealWaveBackground from "components/tealWaveBackground"
import "styles/animations.scss"
import styles from "styles/sections/cta-section.module.scss"
import classNames from "classnames/bind"
import "styles/components/hubspotForm.scss"
import { textWithBreaklineHtml } from 'helpers/helpers'
import HubspotForm from "./hubspotForm"
const cx = classNames.bind(styles)

const CtaWrapper = (props) => {
  if (props.backgroundType === 'teal wave') {
    return (
      <TealWaveBackground>
        <CtaSection {...props} />
      </TealWaveBackground>
    )
  }
  if (
    props.backgroundType === "wavy" &&
    props.previousBackgroundType !== "light mint"
  ) {
    return (
      <WavyBackground bgType={props.bgType} isHideWave={props.isHideWave}>
        <CtaSection {...props} />
      </WavyBackground>
    )
  }
  if (
    props.backgroundType === "wavy mint top" ||
    (props.backgroundType === "wavy" &&
      props.previousBackgroundType === "light mint")
  ) {
    return (
      <WavyBackground bgType={props.bgType} isHideWave={props.isHideWave}>
        <CtaSection {...props} backgroundType="wavy" />
      </WavyBackground>
    )
  }
  return <CtaSection {...props} />
}

const CtaSection = ({
  sectionId,
  title,
  subtitle,
  textAlign,
  link,
  backgroundType,
  overlapsWithPriorSection,
  popupForm
}) => {
  return (
    <section id={sectionId} className={cx("container", {
      wavy: backgroundType === "wavy",
      tealWave: backgroundType === 'teal wave',
      teal: backgroundType === 'teal',
      navy: backgroundType === 'navy NO waves',
      white: backgroundType === "white",
      none: backgroundType === "none"
    })}>
      <div className={cx(styles.textContainer, textAlign === "Center" && styles.textAlign)}>
        <Reveal effect="fadeInUp" duration={1000}>
          <>
            {title && <h2
              className={cx("title", { overlapsWithPriorSection })}
              dangerouslySetInnerHTML={{
                __html: textWithBreaklineHtml(title),
              }}
            />}
            {subtitle && <div
              className={cx('text', link && !link.internalLink && !link.externalLink && 'noMargin')}
              dangerouslySetInnerHTML={{
                __html: subtitle?.childMarkdownRemark?.html,
              }}
            />}
          </>
        </Reveal>
      </div>
      {!popupForm && <Reveal effect="fadeInUp" duration={1000}>
        <ContentfulLink {...link} className={styles.cta}>
          {link?.linkText}
        </ContentfulLink>
      </Reveal>}

      {popupForm && link.linkText && <HubspotForm {...popupForm} text={link.linkText} />}
    </section>
  )
}

export default CtaWrapper

export const ctaSectionFragment = graphql`
  fragment CtaSection on ContentfulCtaSection {
    __typename
    id
    sectionId
    title
    subtitle {
      childMarkdownRemark {
        html
      }
    }
    textAlign
    link {
      ...Link
    }
    backgroundType
    overlapsWithPriorSection
    popupForm {
      id
      formId
      formTarget
      portalId
      subtext {
        subtext
      }
      title
    }
  }
`
