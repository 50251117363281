import React from "react"
import { useBreakpoint } from "hooks/use-breakpoint"
import PaddedContent from "components/paddedContent"

import styles from "styles/components/wavy-background.module.scss"
import styleVars from "styles/variables.scss"

import mobileBackgroundTop from "images/wavy-background-top-mobile.png"
import backgroundTop from "images/wavy-background-top.png"
import classNames from "classnames/bind"
const cx = classNames.bind(styles)

const MAP_BG_TYPE = {
  'white': 'white',
  'navy': 'navy',
  'wavy': 'wavy',
  'wavy mint top': 'wavyMintTop',
  'teal': 'teal',
  'teal wave': 'tealWave',
  'mint': 'mint',
}

// above prop: children content is placed above the wavy background
const WavyBackground = ({ children, above, title, lightMintBackground, bgType, isHideWave }) => {
  const [isTablet, loaded] = useBreakpoint(styleVars.breakpointTablet)

  if (above) {
    return (
      <section className={cx(bgType ? MAP_BG_TYPE[bgType] : lightMintBackground && 'lightMint')}>
        <div className={styles.aboveContainer}>
          <PaddedContent>{title && <h2>{title}</h2>}</PaddedContent>
          <div className={styles.aboveContent}>{children}</div>
        </div>
        {!isHideWave && (
          <div className={cx("backgroundTop")}>
            <div className={cx('lineTop', bgType ? MAP_BG_TYPE[bgType] : lightMintBackground && 'lightMint')} />
            {/* <img src={loaded ? (isTablet ? mobileBackgroundTop : backgroundTop) : undefined} alt={title || 'Wavy Background'} /> */}
            <div className={cx('lineBottom')} />
          </div>
        )}
        {/* <div className={styles.wavyContainer} /> */}
      </section>
    )
  }

  return (
    <div className={cx(bgType ? MAP_BG_TYPE[bgType] : lightMintBackground && 'lightMint')}>
      {!isHideWave && (
        <div className={cx("backgroundTop")}>
          <div className={cx('lineTop', bgType ? MAP_BG_TYPE[bgType] : lightMintBackground && 'lightMint')} />
          <img src={loaded ? (isTablet ? mobileBackgroundTop : backgroundTop) : undefined} alt={title || 'Wavy Background'} />
          <div className={cx('lineBottom')} />
        </div>
      )}
      {children && <div className={styles.wavyContainer}>{children}</div>}
    </div>
  )
}

export default WavyBackground
